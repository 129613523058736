export default class HiRTCSdk {
  /**
   * 构造函数，初始化 HiRTCSdk 类，获取全局的 hirtcwebsdk 对象。
   * 并初始化 SDK
   * @param {object} options - 可选的配置参数
   */
  constructor(options = {}) {
    // 默认配置
    const defaultOptions = {
      serviceID: "3trdd4r4936c1g256huxf70a",
      serviceKey: "00227a744ef48382",
      Services: {
        BasicRoomServiceToken: "https://brs-hirtc.hismarttv.com/v1/auth/token\n",
      },
    };

    // 合并默认配置和传入配置
    this.options = { ...defaultOptions, ...options };

    // 获取全局 hirtcwebsdk 对象
    this.sdk = window.hirtcwebsdk;

    // 初始化 SDK
    this.init(this.options);

    // 错误代码常量定义
    this.ErrorCodes = {
      ParamsError: window.hirtcwebsdk.ErrorCode.ParamsError,
      JoinError: window.hirtcwebsdk.ErrorCode.JoinError,
      PreviewError: window.hirtcwebsdk.ErrorCode.PreviewError,
      PublishError: window.hirtcwebsdk.ErrorCode.PublishError,
      SubscribeError: window.hirtcwebsdk.ErrorCode.SubscribeError,
      SetDeviceError: window.hirtcwebsdk.ErrorCode.SetDeviceError,
      RecordError: window.hirtcwebsdk.ErrorCode.RecordError,
      PrintError: window.hirtcwebsdk.ErrorCode.PrintError,
    };
  }

  /**
   * 初始化 SDK
   * @param {object} options - 配置选项，包含必要的证书ID、KEY等信息
   */
  init(options) {
    if (!this.sdk) {
      throw new Error("hirtcwebsdk 未加载。确保 SDK 脚本已加载。");
    }
    this.sdk.init(options);
  }

  /**
   * 加入房间
   * @param {string} room - 房间号
   * @param {string} uid - 用户ID
   * @param {string} uName - 用户名
   * @param {string} [areaCode='1'] - 地区代码，默认为'1'
   * @param {number} [role=0] - 用户角色，默认为0
   */
  join(room, uid, uName, areaCode = "1", role = 0) {
    this.sdk.join(room, uid, uName, areaCode, role);
  }

  /**
   * 离开房间
   */
  leave() {
    this.sdk.leave();
  }

  /**
   * 预览视频流
   * @param {HTMLElement} videoElement - 视频元素
   * @param {string} streamType - 流类型（camera | screen）
   * @param {boolean} [open=true] - 是否打开预览，默认为true
   */
  preview(videoElement, streamType, open = true) {
    this.sdk.preview(videoElement, streamType, open);
  }

  /**
   * 预览视频流，支持扩展标签
   * @param {HTMLElement} videoElement - 视频元素
   * @param {string} tag - 自定义标签
   * @param {string} audioInputID - 音频输入设备ID
   * @param {string} videoInputID - 视频输入设备ID
   * @param {boolean} open - 是否打开预览
   */
  previewEx(videoElement, tag, audioInputID, videoInputID, open) {
    this.sdk.previewEx(videoElement, tag, audioInputID, videoInputID, open);
  }

  /**
   * 预览视频流，支持扩展标签
   * @param {HTMLElement} videoElement - 视频元素
   * @param {string} tag - 自定义标签
   * @param {boolean} open - 是否打开预览
   */
  previewScreenEx(videoElement, tag, open) {
    this.sdk.previewScreenEx(videoElement, tag, open);
  }

  /**
   * 预览视频流，支持扩展标签
   * @param {string} tag - 自定义标签
   * @param {string} [info] - 自定义附加信息
   */
  publishScreenEx(tag, info) {
    this.sdk.publishScreenEx(tag, info);
  }

  /**
   * 预览视频流，支持扩展标签
   * @param {string} tag - 自定义标签
   */
  unpublishScreenEx(tag) {
    try {
      this.sdk.unpublishScreenEx(tag);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * 发布视频流
   * @param {string} streamType - 流类型（camera | screen）
   * @param {string} [info] - 自定义附加信息
   */
  publish(streamType, info) {
    this.sdk.publish(streamType, info);
  }

  /**
   * 发布视频流，支持自定义标签
   * @param {string} tag - 流标签，用于区分多个相同类型的流
   * @param {string} [info] - 自定义附加信息
   */
  publishEx(tag, info) {
    this.sdk.publishEx(tag, info);
  }

  /**
   * 取消发布视频流
   * @param {string} streamType - 流类型（camera | screen）
   */
  unpublish(streamType) {
    this.sdk.unpublish(streamType);
  }

  /**
   * 取消发布视频流，支持自定义标签
   * @param {string} tag - 自定义标签
   */
  unpublishEx(tag) {
    this.sdk.unpublishEx(tag);
  }

  /**
   * 订阅视频流
   * @param {HTMLElement} videoElement - 视频元素
   * @param {string} userID
   * @param {string} tag - 发布的流ID
   * @param {number} [layerIndex=0] - 视频层级，默认为0
   */
  subscribeEx(videoElement, userID, tag, layerIndex = 0) {
    this.sdk.subscribeEx(videoElement, userID, tag, layerIndex);
  }

  /**
   * 取消订阅视频流
   * @param {string} publishID - 发布流ID
   * @param {string} subscribeID - 订阅流ID
   */
  unsubscribe(publishID, subscribeID) {
    this.sdk.unsubscribe(publishID, subscribeID);
  }
  /**
   * 取消订阅视频流
   * @param {string} userId - 发布流ID
   * @param {string} tag - 订阅流ID
   */
  unsubscribeEx(userId, tag) {
    this.sdk.unsubscribeEx(userId, tag);
  }

  /**
   * 静音本地流
   * @param {string} streamType - 流类型（camera | screen）
   * @param {object} status - 包含muteAudio和muteVideo的状态对象
   */
  muteLocal(streamType, status) {
    this.sdk.muteLocal(streamType, status);
  }

  /**
   * 静音本地流，支持自定义标签
   * @param {string} tag - 自定义标签
   * @param {object} status - 包含muteAudio和muteVideo的状态对象
   */
  muteLocalEx(tag, status) {
    this.sdk.muteLocalEx(tag, status);
  }

  /**
   * 静音远端流
   * @param {string} subscribeID - 订阅流的ID
   * @param {object} status - 包含muteAudio和muteVideo的状态对象
   */
  muteRemote(subscribeID, status) {
    this.sdk.muteRemote(subscribeID, status);
  }

  /**
   * 选择视频层
   * @param {string} subscriberID - 订阅流的ID
   * @param {number} layerIndex - 选择的层索引
   */
  selectLayer(subscriberID, layerIndex) {
    this.sdk.selectLayer(subscriberID, layerIndex);
  }

  /**
   * 选择视频层
   * @param userID
   * @param tag
   * @param layerIndex
   */
  selectLayerEx(userID, tag, layerIndex = 0) {
    this.sdk.selectLayerEx(userID, tag, layerIndex);
  }

  /**
   * 发送消息到房间中的其他用户
   * @param {string} data - 消息内容
   * @param {Array} users - 接收者的用户ID数组
   */
  message(data, users) {
    this.sdk.message(data, users);
  }

  /**
   * 请求设备使用权限，如麦克风和摄像头
   * @param {boolean} audio - 是否请求麦克风权限
   * @param {boolean} video - 是否请求摄像头权限
   */
  askForPermission(audio, video) {
    this.sdk.askForPermission(audio, video);
  }

  /**
   * 获取设备列表
   * @param {boolean} enableDetail - 是否返回详细设备信息
   */
  getDevices(enableDetail) {
    this.sdk.getDevices(enableDetail);
  }

  /**
   * 设置首选设备
   * @param {string} deviceType - 设备类型（audioinput | audiooutput | videoinput）
   * @param {string} deviceID - 设备ID
   */
  setDevice(deviceType, deviceID) {
    this.sdk.setDevice(deviceType, deviceID);
  }

  /**
   * 设置首选设备
   * @param {string} tag - 设tag
   * @param {string} deviceType - 设备类型（audioinput | audiooutput | videoinput）
   * @param {string} deviceID - 设备ID
   */
  setDeviceEx(tag, deviceType, deviceID) {
    this.sdk.setDeviceEx(tag, deviceType, deviceID);
  }

  /**
   * 音频状态报告
   * @param {boolean} enable - 是否启用报告
   * @param {number} interval - 报告间隔（毫秒）
   * @param {HTMLElement} audio - 音频播放元素，用于反馈
   * @param {boolean} enableInspire - 是否启用语音激励反馈
   */
  audioReport(enable = true, interval = 250, audio = null, enableInspire = false) {
    this.sdk.audioReport(enable, interval, audio, enableInspire);
  }

  /**
   * 视频状态报告
   * @param {boolean} enable - 是否启用报告
   * @param {number} interval - 报告间隔（毫秒）
   */
  videoReport(enable = true, interval = 1000) {
    this.sdk.videoReport(enable, interval);
  }

  /**
   * 录制本地流
   * @param {string} [streamType='camera'] - 流类型（camera | screen）
   * @param {boolean} [enable=true] - 是否开始录制
   * @param {boolean} [autoDownload=true] - 是否自动下载录制文件
   * @returns {Promise | undefined} - 如果停止录制，返回Promise
   */
  recordLocal(streamType = "camera", enable = true, autoDownload = true) {
    return this.sdk.recordLocal(streamType, enable, autoDownload);
  }

  /**
   * 录制本地流，支持自定义标签
   * @param {string} tag - 自定义标签
   * @param {boolean} [enable=true] - 是否开始录制
   * @param {boolean} [autoDownload=true] - 是否自动下载录制文件
   * @returns {Promise | undefined} - 如果停止录制，返回Promise
   */
  recordLocalEx(tag, enable = true, autoDownload = true) {
    return this.sdk.recordLocalEx(tag, enable, autoDownload);
  }

  /**
   * 录制远端流
   * @param {string} subscribeID - 订阅流的ID
   * @param {boolean} [enable=true] - 是否开始录制
   * @param {boolean} [autoDownload=true] - 是否自动下载录制文件
   * @returns {Promise | undefined} - 如果停止录制，返回Promise
   */
  recordRemote(subscribeID, enable = true, autoDownload = true) {
    return this.sdk.recordRemote(subscribeID, enable, autoDownload);
  }

  /**
   * 截取并返回本地流的截图
   * @param {string} streamType - 流类型（camera | screen）
   * @returns {string} - 返回截图的图片URL，base64格式
   */
  printLocal(streamType) {
    return this.sdk.printLocal(streamType);
  }

  /**
   * 截取并返回本地流的截图，支持自定义标签
   * @param {string} tag - 自定义标签
   * @returns {string} - 返回截图的图片URL，base64格式
   */
  printLocalEx(tag) {
    return this.sdk.printLocalEx(tag);
  }

  /**
   * 截取并返回远端流的截图
   * @param {string} subStreamID - 订阅流的ID
   * @returns {string} - 返回截图的图片URL，base64格式
   */
  printRemote(subStreamID) {
    return this.sdk.printRemote(subStreamID);
  }

  /**
   * 更新流的自定义信息
   * @param {Array} streams - 包含流信息的数组，每个元素包含publishID和info
   */
  updateInfo(streams) {
    this.sdk.updateInfo(streams);
  }

  updateInfoEx(streams) {
    this.sdk.updateInfoEx(streams);
  }

  /**
   * 开始合流任务
   * @param {number} type - 合流任务的类型指示符，其中：
   *                       0 - 保留，无效
   *                       1 - 直播（2^0）
   *                       2 - 录制（2^1）
   *                       3 - 同时直播和录制（1+2）
   * @param {Object[]} users - 订阅对象数组，包含参与合流的用户和流信息，每个对象包含：
   *                           uid - 用户的唯一标识符
   *                           type - 流类型，如 'camera' 或 'screen'
   *                           tag - 流标签，用于进一步描述或分类流
   * @param {number} layout - 布局枚举值，指定合流视频的布局方式
   * @param {boolean} [autoInsertScreen] - 是否自动将屏幕共享流插入合流
   * @param {boolean} [autoFocusScreen] - 是否自动聚焦屏幕共享流，通常用于突出显示屏幕共享内容
   * @param {boolean} [autoLayout] - 是否自动对合流中的视频流进行布局调整
   * @param {string} [streamServer] - 流服务器的地址，用于指定视频流的推送目标
   * @param {string} [callback] - 在合流任务的关键事件（如开始、结束）发生时触发的回调URL
   */
  startMixer(typ, users, layout, autoInsertScreen = false, autoFocusScreen = false, autoLayout = false, streamServer = "", callback = "") {
    this.sdk.startMixer(typ, users, layout, autoInsertScreen, autoFocusScreen, autoLayout, streamServer, callback);
  }

  /**
   * 暂停或恢复合流任务
   * @param {string} task - 合流任务的ID
   * @param {boolean} pause - true为暂停，false为恢复
   */
  pauseMixer(task, pause) {
    this.sdk.pauseMixer(task, pause);
  }

  /**
   * 停止合流任务
   * @param {string} task - 合流任务的ID
   */
  stopMixer(task) {
    this.sdk.stopMixer(task);
  }

  /**
   * 更新合流任务的配置
   * @param {string} task - 合流任务的ID
   */
  updateMixer(task) {
    this.sdk.updateMixer(task);
  }

  /**
   * 开始页面任务，用于直播或录制网页
   * @param {string} task - 任务的ID
   * @param {number} typ - 任务类型（1-直播，2-录制）
   * @param {number} bps - 码率
   * @param {number} fps - 帧率
   * @param {number} width - 视频宽度
   * @param {number} height - 视频高度
   * @param {string} url - 网页URL
   * @param {string} streamServer - 推流服务器地址
   * @param {string} [callback] - 回调URL
   */
  startWebPage(task, typ, bps, fps, width, height, url, streamServer, callback) {
    this.sdk.startWebPage(task, typ, bps, fps, width, height, url, streamServer, callback);
  }

  /**
   * 暂停或恢复页面任务
   * @param {string} task - 任务的ID
   * @param {boolean} pause - true为暂停，false为恢复
   */
  pauseWebPage(task, pause) {
    this.sdk.pauseWebPage(task, pause);
  }

  /**
   * 停止页面任务
   * @param {string} task - 任务的ID
   */
  stopWebPage(task) {
    this.sdk.stopWebPage(task);
  }

  /**
   * 设置用户角色
   * @param {number} role - 用户角色，根据业务定义
   */
  setRole(role) {
    this.sdk.setRole(role);
  }

  /**
   * 检查客户端运行环境的支持情况
   * @returns {object} - 包含对WebSocket、WebRTC等技术的支持情况
   */
  checkRequirement() {
    return this.sdk.checkRequirement();
  }

  /**
   * 设置推流层的详细参数，如分辨率和帧率
   * @param {string} type - 类型, camera 或者 tag
   * @param {object} layer - 层级信息，包含width, height, frameRate, targetBw, layerIndex
   */
  setPublishLayer(type, layer) {
    this.sdk.setPublishLayer(type, layer);
  }

  /**
   * 设置音频采集音量
   * @param {string} streamType - 流类型，如camera或screen
   * @param {number} volume - 音量大小，范围0-100
   * @returns {boolean} - 设置成功返回true，失败返回false
   */
  setCaptureVolume(streamType, volume) {
    return this.sdk.setCaptureVolume(streamType, volume);
  }

  /**
   * 设置自定义流的音频采集音量
   * @param {string} tag - 自定义流的标签
   * @param {number} volume - 音量大小，范围0-100
   * @returns {boolean} - 设置成功返回true，失败返回false
   */
  setCaptureVolumeEx(tag, volume) {
    return this.sdk.setCaptureVolumeEx(tag, volume);
  }

  /**
   * 开启全局静音
   */
  pauseAllStreamAudio() {
    return this.sdk.pauseAllStreamAudio();
  }

  /**
   * 取消全局静音
   */
  resumeAllStreamAudio() {
    return this.sdk.resumeAllStreamAudio();
  }

  /**
   * 注册事件监听器
   * @param {string} event - 事件名
   * @param {function} listener - 处理事件的函数
   */
  addListener(event, listener) {
    this.sdk.addListener(event, listener);
  }

  /**
   * 移除指定的事件监听器
   * @param {string} event - 事件名
   * @param {function} listener - 需要移除的监听器
   */
  removeListener(event, listener) {
    this.sdk.removeListener(event, listener);
  }

  /**
   * 移除某个事件的所有监听器
   * @param {string} event - 事件名
   */
  removeAllListeners(event) {
    this.sdk.removeAllListener(event);
  }
}
